export const path = {
    HOME: '/',
    DETAIL: '/detail',
    POST: '/:slug',
    ABOUT: '/about-us',
    POLICY: '/privacy-policy',
    CONTACT: '/contact-us',
    LOGIN: '/login',
    LOG_OUT: '/logout',
    SYSTEM: '/system'
};

export const LANGUAGES = {
    JA: 'ja',
    VI: 'vi',
    EN: 'en'
};

export const manageActions = {
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE",
    READ: "READ"
};

export const dateFormat = {
    SEND_TO_SERVER: 'DD/MM/YYYY'
};

export const YesNoObj = {
    YES: 'Y',
    NO: 'N'
}

export const USER_ROLE = {
    ADMIN: 'R1',
    DOCTOR: 'R2',
    PATIENT: 'R3'
}

export const ALLCODE_TYPE = {
    ROLE: 'ROLE',
    STATUS: 'STATUS',
    TIME: 'TIME',
    POSITION: 'POSITION',
    GENDER: 'GENDER',
    PRICE: 'PRICE',
    PAYMENT: 'PAYMENT',
    PROVINCE: 'PROVINCE',
}

export const PATIENT_STATUS = {
    NEW: 'S1',
    CONFIRM: 'S2',
    DONE: 'S3',
    CANCEL: 'S4'
}

export const TEXT_WIDGET = {
    ABOUT_US_SIDEBAR: 'about-us-sidebar',
    PRIVACY_POLICY: 'privacy-policy',
    TEXT_HEADER: 'text-header',
    CONTACT_US: 'contact-us',
}

