import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { changeLanguageApp } from '../../../store/actions';
import { CommonUtils, LANGUAGES, TEXT_WIDGET, path } from '../../../utils';
import laravelService from '../../../services/laravelService';
import PostItem from '../../../components/PostItem';
// import { useParams } from "react-router-dom";
// import './HomeHeader.scss';
const storagePath = CommonUtils.getStoragePath();


// const surl1 = storagePath + 'images/slices/photo-1.jpeg';
// const surl2 = storagePath + 'images/slices/photo-2.jpeg';
// const surl3 = storagePath + 'images/slices/photo-3.jpeg';
// const surl4 = storagePath + 'images/slices/photo-4.jpeg';
// const surl5 = storagePath + 'images/slices/photo-5.jpeg';
// const surl6 = storagePath + 'images/slices/photo-6.jpeg';
// const surl7 = storagePath + 'images/slices/photo-1.jpeg';
// const surl8 = storagePath + 'images/slices/photo-2.jpeg';
// const surl9 = storagePath + 'images/slices/photo-3.jpeg';

class PostSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            //links: null,
            tabLinks: [],
            currentPage: 1,
            detail: false,
            postDetail: null,
            postNext: null,
            postPrev: null,
            // slug: ""
        };
    };

    async getPosts(currentPage = null) {
        let res = await laravelService.getAllPosts(currentPage);
        if (res && res.data) {
            // console.log(res);
            this.setState({
                posts: res.data,
                //links: res.links,
                tabLinks: res.meta.links,
            });
        }
    };

    async getPostsByCategory(slug) {
        let res = await laravelService.getPostsByCategory(slug);

        console.log("PostSection res ", res, res.data.length, res.data, res.data.length);
        if (res && res.data.length > 0) {
            console.log("set state res ");
            this.setState({
                posts: res.data,
                // links: res.links,
                tabLinks: res.meta.links,
            });
        } else {
            console.log("response error: ", res);
        }
    };

    async componentDidMount() {
        if (!this.props.slug || this.props.slug === "") {

            console.log('PostSection componentDidMount getPosts : ', this.props.slug);
            this.getPosts(null);
        } else {
            console.log("PostSection componentDidMount: ", this.props.slug)
            this.getPostsByCategory(this.props.slug)
        }
        // const params = useParams();

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.currentPage !== this.state.currentPage) {
            console.log("componentDidUpdate prevState:", prevState);
            this.getPosts(this.state.currentPage);
        }
        if (prevProps.slug !== this.props.slug) {
            console.log("componentDidUpdate prevProps: ", prevProps.slug, this.props.slug)
            this.props.slug ? this.getPostsByCategory(this.props.slug) : this.getPosts(null);
            this.setState({
                detail: false
            })
        }

    }

    handleClickPage = (page) => {
        this.setState({
            currentPage: page,
        })
    }

    handleClickPrev = () => {
        this.setState({
            currentPage: parseInt(this.state.currentPage) - 1,
        })
    }

    handleClickNext = () => {
        this.setState({
            currentPage: parseInt(this.state.currentPage) + 1,
        })
    }
    // getCarouselData = () => {
    //     return {
    //         currentIndex: 0,
    //         images: [
    //             surl1 + '?sig=1',
    //             surl2 + '?sig=2',
    //             surl3 + '?sig=3',
    //             surl4 + '?sig=4',
    //             surl5 + '?sig=5',
    //             surl6 + '?sig=6',
    //             surl7 + '?sig=7',
    //             surl8 + '?sig=8',
    //             surl9 + '?sig=9',
    //         ],
    //         increment() {
    //             this.currentIndex = this.currentIndex === this.images.length - 6 ? 0 : this.currentIndex + 1;
    //         },
    //         decrement() {
    //             this.currentIndex = this.currentIndex === this.images.length - 6 ? 0 : this.currentIndex - 1;
    //         },
    //     }
    // }

    handleClickPost = (post) => {

        console.log("post ::::::: ", post);
        if (!this.state.detail) {
            this.setState({
                detail: true
            })
        }

        if (post) {
            this.setState({
                postDetail: post
            })
        }

    };

    render() {
        const language = this.props.language;
        const { posts, tabLinks, currentPage, detail, postDetail } = this.state;
        console.log('duong posts: ', posts, tabLinks);

        let categories = null;
        let prev = null;
        let next = null;
        if (postDetail) {
            categories = categories && categories.length > 0 ? postDetail.categories : null;
            if (posts && posts.length > 0) {
                const currentIndex = posts.findIndex(post => post.id === postDetail.id);
                console.log('currentindex::::: ', currentIndex);
                if (currentIndex > 0) {
                    prev = posts[currentIndex - 1] ? posts[currentIndex - 1] : null;
                }
                next = posts[currentIndex + 1] ? posts[currentIndex + 1] : null;
            }
            // console.log('prev next :', postDetail, prev, next);
            document.title = postDetail.meta_title ? postDetail.meta_title : CommonUtils.getTitleBylanguage(postDetail, language);
            // document.description = postDetail.meta_description;
            document.getElementsByTagName("META")[4].content = postDetail.meta_description;

        }
        let user = null;
        if (postDetail) {
            user = postDetail.user;
        }

        let active = "hidden h-10 w-10 bg-blue-800 hover:bg-blue-600 font-semibold text-white text-sm sm:flex sm:items-center sm:justify-center";
        let non_active = "hidden h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm sm:flex sm:items-center sm:justify-center";

        return (
            <section className="w-full md:w-2/3 flex flex-col items-center px-3">
                {!detail ?
                    (
                        <>
                            {
                                (posts && posts.length > 0) &&
                                posts.map((item, index) => {
                                    return (
                                        <PostItem post={item} key={index} storagePath={storagePath} handleClickPost={this.handleClickPost} />
                                    )
                                })
                            }

                            {

                                (tabLinks && tabLinks.length > 0) &&
                                <div className="flex items-center py-8">
                                    {
                                        tabLinks.map((item, index) => {
                                            return (
                                                item.url ?
                                                    item.label.includes('Previous') ?
                                                        <a key={index} href="#" onClick={() => this.handleClickPrev()} className="h-10 w-15 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3"><i className="fas fa-arrow-left mr-2"></i> <FormattedMessage id="PostSection.previous" /></a>
                                                        :
                                                        (
                                                            item.label.includes('Next') ?
                                                                <a key={index} href="#" onClick={() => this.handleClickNext()} className="h-10 w-15 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3"><FormattedMessage id="PostSection.next" /> <i className="fas fa-arrow-right ml-2"></i></a>
                                                                :
                                                                <a key={index} href="#" onClick={() => this.handleClickPage(item.label)} className={item.active ? active : non_active}>{item.label}</a>
                                                        )
                                                    :
                                                    ""
                                            );
                                        })

                                    }
                                </div>
                            }
                        </>
                    )
                    :
                    (
                        <>
                            <article className="flex flex-col shadow my-4" id={postDetail.title_JP}>
                                <a href="#" className="hover:opacity-75">
                                    <img src={postDetail ? CommonUtils.getUrlImage(postDetail.thumbnail, storagePath) : ""} />
                                </a>
                                <div className="bg-white flex flex-col justify-start p-6">
                                    <div className="flex gap-4">
                                        {categories && categories.length > 0 &&
                                            categories.map((item, index) => {
                                                return <a href="#" className="text-blue-700 text-sm font-bold uppercase pb-4" key={index}>{item.title_JP}</a>
                                            })
                                        }
                                    </div>
                                    <h1 className="text-3xl font-bold hover:text-gray-700 pb-4">{postDetail ? CommonUtils.getTitleBylanguage(postDetail, language) : ""}</h1>
                                    <p href="#" className="text-sm pb-8">
                                        By <a href="#" className="font-semibold hover:text-gray-800">{user && user.name ? user.name : ""}</a>, Published on {postDetail.getFormattedDateJp}
                                    </p>
                                    <div className="pb-2" dangerouslySetInnerHTML={{ __html: postDetail ? CommonUtils.getBodyBylanguage(postDetail, language) : "" }} />
                                </div>
                            </article>

                            <div className="w-full flex pt-6">
                                <div className="w-1/2">
                                    {prev &&
                                        <a className="block w-full bg-white shadow hover:shadow-md text-left p-6" onClick={() => this.handleClickPost(prev)}>
                                            <p className="text-lg text-blue-800 font-bold flex items-center"><i className="fas fa-arrow-left pr-1"></i> <FormattedMessage id="PostSection.previous" /></p>
                                            <p className="pt-2">{CommonUtils.getShortTitleBylanguage(prev, language)}</p>
                                        </a>
                                    }
                                </div>
                                <div className="w-1/2">
                                    {next &&
                                        <a className="block w-full bg-white shadow hover:shadow-md text-right p-6" onClick={() => this.handleClickPost(next)}>
                                            <p className="text-lg text-blue-800 font-bold flex items-center justify-end"><FormattedMessage id="PostSection.next" /> <i className="fas fa-arrow-right pl-1"></i></p>
                                            <p className="pt-2">{CommonUtils.getShortTitleBylanguage(next, language)}</p>
                                        </a>
                                    }
                                </div>
                            </div>


                        </>
                    )
                }

            </section>

        );
    }

}

const mapStateToProps = state => {
    return {
        // isLoggedIn: state.user.isLoggedIn,
        language: state.app.language,
        slug: state.app.slug
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // changeLanguageAppRedux: (language) => dispatch(changeLanguageApp(language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostSection);


