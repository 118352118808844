import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { changeLanguageApp } from '../../../store/actions';
import { CommonUtils, LANGUAGES, LanguageUtils, TEXT_WIDGET, path } from '../../../utils';
import laravelService from '../../../services/laravelService';
// import './HomeHeader.scss';
const storagePath = CommonUtils.getStoragePath();
const url1 = storagePath + 'images/sharehouses/sharehouseH&Q.jpeg';
const url2 = storagePath + 'images/sharehouses/Tokyo_adachiku.jpeg';
const url3 = storagePath + 'images/sharehouses/Ichikawa-shi.jpg';

// const images = [
//     url1, url2, url3
// ];

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textWidget: null,
            companyDetail: false,
            categories: [],
            category: null,
            slug: "",
            largeImage: "",
            images: [],
        };
    };

    async getTextWidget() {
        let res = await laravelService.getTextWidget(TEXT_WIDGET.ABOUT_US_SIDEBAR);
        if (res && res.data) {
            // console.log(res);
            this.setState({
                textWidget: res.data,
            });
        }
    };

    async getCategories() {
        let res = await laravelService.getCategories();
        if (res && res.length > 0) {
            this.setState({
                categories: res,
            });
        }
    };

    async getImages() {
        let res = await laravelService.getImageSides();
        console.log("res ::::", res);
        if (res && res.data && res.data.length > 0) {
            this.setState({
                images: res.data,
            });
        }
    };

    async componentDidMount() {
        this.getTextWidget();
        this.getCategories();
        this.getImages();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.slug !== this.props.slug || this.state.slug !== this.props.slug) {
            this.setState({
                slug: this.props.slug,
            })
        }
        if (prevProps.language !== this.props.language && this.state.category) {
            CommonUtils.setMetaCateBylanguage(this.state.category, this.props.language);
        }
    }


    handleCompanyDetail() {
        this.setState({
            companyDetail: !this.state.companyDetail
        })
    }

    handleGetPostsByCategory = (item) => {
        this.setState({
            slug: item.slug,
            category: item.category
        });
        //console.log("Sidebar handleGetPostsByCategory : ", item, this.props);
        this.props.handleGetPostsByCategory(item.slug);
        CommonUtils.setMetaCateBylanguage(item, this.props.language);

    }
    handleViewAbout = () => {
        this.props.handleViewAbout();
    }
    handleShowImage = (image) => {
        this.setState({
            largeImage: image,
        })
    }
    handleCloseImage = () => {

        this.setState({
            largeImage: '',
        });
    }

    // const handleOutsideClick = (event) => {
    //     if (!galleryRef.current.contains(event.target)) {
    //       setLargeImage(null);
    //     }
    //   };

    render() {
        const language = this.props.language;
        const { slug, textWidget, categories, companyDetail, largeImage, images } = this.state;
        console.log("images:", images);
        return (
            <aside className="w-full md:w-1/3 flex flex-col items-center px-3">
                <div className="w-full bg-white shadow flex flex-col my-4 p-6">
                    <h3 className='text-xl font-semibold mb-3'><FormattedMessage id="Sidebar.all-categories" /></h3>
                    {
                        categories.map((item, index) => {
                            return (
                                // <a href={"#" + CommonUtils.getTitleBylanguage(item, language)} onClick={() => this.handleGetPostsByCategory(item)} className={item.slug === slug ? "bg-blue-600 text-white text-semibold block py-2 px-3 rounded" : "text-semibold block py-2 px-3 rounded"} key={index}>
                                <Link to={"/" + item.slug} onClick={() => this.handleGetPostsByCategory(item)} className={item.slug === slug ? "bg-blue-600 text-white text-semibold block py-2 px-3 rounded" : "text-semibold block py-2 px-3 rounded"} key={index}>
                                    {CommonUtils.getTitleBylanguage(item, language)} ({item.total})
                                </Link>
                            );
                        })
                    }
                </div>

                <div className="w-full bg-white shadow flex flex-col my-4 p-6">
                    <p className="text-xl font-semibold pb-3">{textWidget ? CommonUtils.getTitleBylanguage(textWidget, language) : ""}</p>
                    <div className="pb-2" dangerouslySetInnerHTML={{
                        __html: !companyDetail ? (textWidget ? CommonUtils.getShortContentBylanguage(textWidget, language) : "")
                            : (textWidget ? CommonUtils.getContentBylanguage(textWidget, language) : "")
                    }} />
                    <a href={path.ABOUT} onClick={() => this.handleViewAbout()} className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4">
                        もっと見る
                    </a>

                    {/* {
                        !companyDetail &&
                        <a href="#" onClick={() => this.handleCompanyDetail()} className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4">
                            さらにくわしくみる
                        </a>
                    } */}
                    {/* {
                        companyDetail &&
                        <a href="#" onClick={() => this.handleCompanyDetail()} className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4">
                            隠す
                        </a>
                    } */}
                </div>
                <div className="w-full bg-white shadow flex flex-col my-4 p-6">
                    <p className="text-xl font-semibold pb-5"><FormattedMessage id="Sidebar.activity" /></p>
                    <div className="grid grid-cols-3 gap-3 image-gallegy">
                        <div className="thumbnail-container">
                            {
                                images.map((image, index) => (
                                    <div className='thumbnail' key={index}
                                        onClick={() => this.handleShowImage(CommonUtils.getUrlImage(image.image_path, CommonUtils.getStoragePath()))}
                                    >
                                        <img className='hover:opacity-75' src={CommonUtils.getUrlImage(image.image_path, CommonUtils.getStoragePath())} alt={'Thumbnail ${index}'} />
                                    </div>
                                ))
                            }
                            {largeImage &&
                                <div className='preview overlay' onClick={() => this.handleCloseImage()}>
                                    <img src={largeImage} alt={'Preview'} />
                                </div>
                            }
                        </div>
                    </div>
                    <a href="https://www.facebook.com/quynh.nguyen.14473426" target="_blank" className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-6">
                        <i className="fab fa-instagram mr-2"></i> Follow @QuynhNguyen
                    </a>
                </div>

            </aside>

        );
    }

}

const mapStateToProps = state => {
    return {
        // isLoggedIn: state.user.isLoggedIn,
        language: state.app.language,
        slug: state.app.slug
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // changeLanguageAppRedux: (language) => dispatch(changeLanguageApp(language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);


