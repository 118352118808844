import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { changeLanguageApp, changeSlugApp } from '../../../store/actions';
import { CommonUtils, LANGUAGES, LanguageUtils, path } from '../../../utils';
import laravelService from '../../../services/laravelService';
// import './HomeHeader.scss';

class Topic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            open: false,
            category: null,
        };
    };

    async getCategoriesTopics() {
        let res = await laravelService.getCategoriesTopics();
        if (res && res.data.length > 0) {
            this.setState({
                categories: res.data,
            });
        }
    };

    async componentDidMount() {
        this.getCategoriesTopics();

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language && this.state.category) {
            CommonUtils.setMetaCateBylanguage(this.state.category, this.props.language);
        }
    }
    handleViewHome = () => {
        this.props.changeSlugAppRedux("");

    }
    handleClickTopics = () => {
        this.setState({
            open: !this.state.open,
        });
    }
    handleGetPostsByCategory = (item) => {
        this.setState({
            slug: item.slug,
            category: item
        });
        this.props.handleGetPostsByCategory(item.slug);
        CommonUtils.setMetaCateBylanguage(item, this.props.language);

    }
    // handleViewAbout = () => {
    //     this.props.handleViewAbout();
    // }

    render() {
        let language = this.props.language;

        const { categories, open } = this.state;
        return (
            <nav className="w-full py-4 border-t border-b bg-gray-100" >
                <div className="block sm:hidden">
                    <a
                        href="#"
                        className="block md:hidden text-base font-bold uppercase text-center flex justify-center items-center"
                        onClick={() => this.handleClickTopics()}
                    >
                        <FormattedMessage id="Topic.topics" /> <i className={open ? "fa-chevron-down fas ml-2" : "fa-chevron-up fas ml-2"}></i>
                    </a>
                </div>
                <div open className={open ? "block w-full flex-grow sm:flex sm:items-center sm:w-auto" : "hidden w-full flex-grow sm:flex sm:items-center sm:w-auto"} >
                    <div className="w-full topics container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm font-bold uppercase mt-0 px-0 py-2">

                        {/* <a href={path.HOME} onClick={() => this.handleViewHome()} className="hover:bg-blue-600 hover:text-white rounded py-2 px-2 mx-2"><FormattedMessage id="Topbar.home" /></a> */}
                        <a href={path.HOME} onClick={() => this.handleViewHome()} className="hover:bg-blue-600 hover:text-white rounded py-2 px-0 mx-2"><FormattedMessage id="Topbar.home" /></a>

                        {
                            categories.map((item, index) => {

                                return (
                                    // <a href={"#" + CommonUtils.getTitleBylanguage(item, language)} onClick={() => this.handleGetPostsByCategory(item.slug)} className={item.slug === slug ? "bg-blue-600 text-white rounded py-2 px-2 mx-2" : "hover:bg-blue-600 hover:text-white rounded py-2 px-2 mx-2"} key={index}>{CommonUtils.getTitleBylanguage(item, language)}</a>
                                    <Link to={"/" + item.slug} onClick={() => this.handleGetPostsByCategory(item)} className="hover:bg-blue-600 hover:text-white rounded py-2 px-0 mx-2" key={index}>{CommonUtils.getShortTitleBylanguage(item, language)}</Link>
                                )
                            })
                        }
                        {/* <a href={"#" + LanguageUtils.getMessageByKey("Topic.about-us", language)} onClick={() => this.handleViewAbout()} className={slug === "about" ? "bg-blue-600 text-white rounded py-2 px-2 mx-2" : "hover:bg-blue-600 hover:text-white rounded py-2 px-2 mx-2"}><FormattedMessage id="Topic.about-us" /></a> */}
                        <Link to={path.ABOUT} className="hover:bg-blue-600 hover:text-white rounded py-2 px-0 mx-2"><FormattedMessage id="Topic.about-us" /></Link>
                    </div>
                </div>
            </nav >

        );
    }

}

const mapStateToProps = state => {
    return {
        // isLoggedIn: state.user.isLoggedIn,
        language: state.app.language,
        slug: state.app.slug
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeSlugAppRedux: (slug) => dispatch(changeSlugApp(slug))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topic);


