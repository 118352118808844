import { result } from "lodash";
import LanguageUtils from "./LanguageUtils";
import { LANGUAGES } from "./constant";

class CommonUtils {
    static isNumber1(number) {
        if (number === 1) return true;
        return false;
    }

    /**
     * get the first element of the array to initialize a component
     * @param {*} array 
     * @returns 
     */
    static getFirstValueOfArr(array) {
        return array && array.length > 0 ? array[0].key : '';
    }

    /**
     * covert image to base 64 to save file to DB with BLOB format
     * @param {*} file 
     * @returns base64 encoded
     */
    static getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /**
     * convert base64 to URL to display the image
     * @param bufferBase64
     * @return url string
     */
    static getUrlFromBase64(base64) {
        return new Buffer.from(base64, 'base64').toString('binary');
    }

    /**
     * convert the first letter of string to upper case
     * @param string
     * @return string
     */
    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static getUrlImage(imgUrl, root) {
        if (!imgUrl) return "";
        const match = /^http/;
        if (imgUrl.match(match)) {
            return imgUrl;
        }
        return root + imgUrl;
    }

    static getStoragePath() {
        //'http://localhost/storage/'

        let backUrl = process.env.REACT_APP_BACKEND_URL;
        let storageStr = process.env.REACT_APP_BACKEND_STORAGE_URL;
        let result = backUrl + "/" + storageStr;

        // backUrl = "/";
        // let result = "";
        if (!backUrl || backUrl.length <= 1) {
            backUrl = window.location.href;
            // console.log("arr ::::: ", backUrl);

            let index = backUrl.lastIndexOf("/");
            result = backUrl.substring(0, index + 1) + storageStr;
        }

        return result;
    }

    /**
     * build data of all_code for select react 
     * @param inputData object
     * @return selectSource object
     */
    static buildDataSelectForAllCode = (inputData, language) => {
        let selectSource = [];

        if (inputData && inputData.length > 0) {
            inputData.map((item, index) => {
                let object = {};
                let labelVi = `${item.valueVi}`;
                let labelEn = `${item.valueEn}`;
                object.label = language === LANGUAGES.VI ? labelVi : labelEn;
                object.value = item.key;
                selectSource.push(object);
            })
        }
        return selectSource;
    }

    static buildForCategoriesSelect = (categories, language) => {
        let selectSource = [];
        if (categories && categories.length > 0) {
            categories.map((category) => {
                let object = {};
                object.label = language === LANGUAGES.VI ? category.title
                    :
                    (language === LANGUAGES.JA ? category.title_JP : category.title_EN);
                object.value = category.id;
                selectSource.push(object);
            })
        }
        return selectSource;
    }

    static setMetaCateBylanguage(obj, language) {
        document.title = obj.title ? this.getTitleBylanguage(obj, language) : LanguageUtils.getMessageByKey("Meta.title-category", language);
        document.getElementsByTagName("META")[4].content = LanguageUtils.getMessageByKey("Meta.description-category", language);
    }

    static getTitleBylanguage(obj, language) {
        if (language === LANGUAGES.VI && obj.title) {
            return obj.title;
        } else if (language === LANGUAGES.EN && obj.title_EN) {
            return obj.title_EN;
        } else if (language === LANGUAGES.JA && obj.title_JP) {
            return obj.title_JP;
        }
        return "";
    }

    static getShortTitleBylanguage(obj, language) {
        if (language === LANGUAGES.VI && obj.shortTitle) {
            return obj.shortTitle;
        } else if (language === LANGUAGES.EN && obj.shortTitle_EN) {
            return obj.shortTitle_EN;
        } else if (language === LANGUAGES.JA && obj.shortTitle_JP) {
            return obj.shortTitle_JP;
        }
        return "";
    }

    static getContentBylanguage(obj, language) {
        if (language === LANGUAGES.VI && obj.content) {
            return obj.content;
        } else if (language === LANGUAGES.EN && obj.content_EN) {
            return obj.content_EN;
        } else if (language === LANGUAGES.JA && obj.content_JP) {
            return obj.content_JP;
        }
        return "";
    }

    static getShortContentBylanguage(obj, language) {
        if (language === LANGUAGES.VI && obj.shortContent) {
            return obj.shortContent;
        } else if (language === LANGUAGES.EN && obj.shortContent_EN) {
            return obj.shortContent_EN;
        } else if (language === LANGUAGES.JA && obj.shortContent_JP) {
            return obj.shortContent_JP;
        }
        return "";
    }

    static getBodyBylanguage(obj, language) {
        if (language === LANGUAGES.VI && obj.body) {
            return obj.body;
        } else if (language === LANGUAGES.EN && obj.body_EN) {
            return obj.body_EN;
        } else if (language === LANGUAGES.JA && obj.body_JP) {
            return obj.body_JP;
        }
        return "";
    }

    static getShortBodyBylanguage(obj, language) {
        if (language === LANGUAGES.VI && obj.shortBody) {
            return obj.shortBody;
        } else if (language === LANGUAGES.EN && obj.shortBody_EN) {
            return obj.shortBody_EN;
        } else if (language === LANGUAGES.JA && obj.shortBody_JP) {
            return obj.shortBody_JP;
        }
        return "";
    }

}

export default CommonUtils;