import Sidebar from "../../../containers/HomePage/Section/Sidebar";
import Topbar from "../../../containers/HomePage/Section/Topbar";
import Topic from "../../../containers/HomePage/Section/Topic";
import { useDispatch } from 'react-redux';
import { changeSlugApp } from "../../../store/actions";
import Footer from "../../../containers/HomePage/Section/Footer";
import { useParams } from "react-router-dom";
import '../../../containers/HomePage/HomePage.scss';


function DefaultLayout({ children }) {
    const params = useParams();
    console.log("params.slug :", params.slug);

    const dispatch = useDispatch();

    dispatch(changeSlugApp(params.slug));

    const handleGetPostsByCategory = (slug) => {
        dispatch(changeSlugApp(slug));
    }

    return (
        <div className="home-container bg-white font-family-karla">
            <Topbar />
            <Topic
                handleGetPostsByCategory={handleGetPostsByCategory} />
            <div className="container mx-auto flex flex-wrap py-6">
                <>
                    {children}
                </>
                <Sidebar
                    handleGetPostsByCategory={handleGetPostsByCategory} />
            </div>
            <Footer />
        </div>
    )
}

export default DefaultLayout;

