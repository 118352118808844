"use client";

import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { DevTool } from "@hookform/devtools";
import useWeb3Forms from "@web3forms/react";
import {
    MapPinIcon,
    EnvelopeIcon,
    PhoneIcon
} from "@heroicons/react/24/outline";
import Container from "./Container";
import laravelService from "../services/laravelService";
import Select, { components, ControlProps } from 'react-select';
import { CommonUtils, LANGUAGES, LanguageUtils, TEXT_WIDGET } from "../utils";
import { toast } from "react-toastify";
const controlStyles = {
    border: '1px solid black',
    padding: '5px',
    background: "yellow",
    color: 'black',
};
const ControlComponent = (props) => (
    <div style={controlStyles}>
        <p>Custom Control</p>
        <components.Control {...props} />
    </div>
);

// let renderCount = 0;
// let language = "";

export default function Contact({ settings }) {

    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState(false);
    const language = useSelector(state => state.app.language);
    const languageRef = useRef();
    useEffect(() => {
        languageRef.current = language;
    }, [language])

    const [options, setOptions] = useState([]);
    const [contact, setContact] = useState(
        async () => {
            try {
                let res = await laravelService.getTextWidget(TEXT_WIDGET.CONTACT_US);
                if (res && res.data) {
                    setContact(res.data);
                }
            } catch (e) {
                console.log("error fetching contacts:", e);
            }
        }
    );

    const [categories, setCategories] = useState(
        async () => {
            try {
                let res = await laravelService.getCategories();
                console.log("res:", res);
                if (res && res.length > 0) {
                    setCategories(res);
                    // console.log("language: ", languageRef.current);
                    setOptions(CommonUtils.buildForCategoriesSelect(res, languageRef.current));
                }
            } catch (e) {
                console.log("error fetching categories:", e);
            }
        }
    );

    useEffect(() => {
        setOptions(CommonUtils.buildForCategoriesSelect(categories, language));
    }, [language]);

    console.log("categories : ", language, categories, contact, options);
    const form = useForm({
        defaultValues: {
            fullName: "",
            email: "",
            furigana: "",
            companyName: "",
            phone: "",
            postalCode: "",
            address: "",
            content: "",
            category_id: [],
        }
    });
    const { register, control, handleSubmit, formState: { errors, isSubmitSuccessful, isSubmitting, isDirty, isValid }, setValue, reset, setFocus } = form;
    // renderCount++;

    const resetForm = () => {
        setValue("fullName", "");
        setValue("furigana", "");
        setValue("email", "");
        setValue("companyName", "");
        setValue("phone", "");
        setValue("postalCode", "");
        setValue("address", "");
        setValue("content", "");
        setValue("category_id", []);
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    const onSubmit = async (data) => {
        data.categories = data.category_id.map((category) => category.value);
        delete data.category_id;
        data.language = language;
        // console.log('Form is onSubmited: ', data);

        try {
            let res = await laravelService.createContact(data);

            console.log('Form is onSubmited res : ', res.data);
            if (res && (res.data || res.errCode === 0)) {
                // toast.success(LanguageUtils.getMessageByKey("Contact.send-success", language));
                toast.success("successfully sent");
                setIsSuccess(true);
                // resetForm();

            } else {
                toast.error(LanguageUtils.getMessageByKey("Contact.send-fail", language));
                console.log(res && res.message ? res.message : "response failed");
                setIsSuccess(false);
            }

        } catch (e) {
            console.log(e);
            setIsSuccess(false);
        }


        console.log('Form is onSubmited: ', data);

    }

    // const h1Ref = useRef();

    const handleKeyDown = (e, next) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            console.log("Tab key pressed", next);
            // nextRef.current.focus();
            setFocus(next);
        }
    }

    // useEffect(() => {
    //     console.log("furigana : ", h1Ref.current);
    // });

    return (
        <Container>
            <h1 className="mt-2 mb-3 text-3xl font-semibold tracking-tight text-center lg:leading-snug text-brand-primary lg:text-4xl dark:text-white">
                {CommonUtils.getTitleBylanguage(contact, language)}
                {/* ({renderCount / 2}) */}
            </h1>
            <div className="text-center" dangerouslySetInnerHTML={{
                __html: contact ? CommonUtils.getContentBylanguage(contact, language) : ""
            }}
            />

            <div>
                <form onSubmit={handleSubmit(onSubmit)} className="grid my-10 gap-10 md:grid-cols-2" noValidate>
                    <div>
                        <div tabIndex="1" className="mb-5">
                            <input
                                onKeyDown={(e) => handleKeyDown(e, "furigana")}
                                type="text"
                                placeholder={LanguageUtils.getMessageByKey("Contact.fullName", language)}
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.fullName
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }
                                    `}
                                id="fullName"
                                {...register("fullName", {
                                    required: LanguageUtils.getMessageByKey("Errors.fullName", language),
                                    maxLength: 255
                                })}

                            />
                            {errors.fullName && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.fullName.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="3" className="mb-5">
                            <label htmlFor="email" className="sr-only">
                                Email Address
                            </label>
                            <input
                                onKeyDown={(e) => handleKeyDown(e, "companyName")}
                                name="email"
                                type="email"
                                placeholder={LanguageUtils.getMessageByKey("Contact.email", language)}
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.email
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                id="email"
                                {...register("email", {
                                    required: LanguageUtils.getMessageByKey("Errors.email", language),
                                    maxLength: 320,
                                    pattern: {
                                        // value: /^\S+@\S+$/i,
                                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                        message: LanguageUtils.getMessageByKey("Errors.mail-valid", language)
                                    },
                                    validate: {
                                        notAdmin: (fieldValue) => {
                                            return (
                                                fieldValue !== "admin@example.com" ||
                                                LanguageUtils.getMessageByKey("Errors.mail-notAdmin", language)
                                            )
                                        },
                                        notBlackListed: (fieldValue) => {
                                            return !fieldValue.endsWith("baddomain.com") ||
                                                LanguageUtils.getMessageByKey("Errors.mail-notBlackListed", language)
                                        }
                                    }
                                })}
                            />
                            {errors.email && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.email.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="5" className="mb-5">
                            <input
                                onKeyDown={(e) => handleKeyDown(e, "postalCode")}
                                type="text"
                                placeholder={LanguageUtils.getMessageByKey("Contact.phone", language)}
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.phone
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                id="phone"
                                {...register("phone", {
                                    maxLength: 20,
                                    pattern: {
                                        value: /^\+?[0-9]\d{1,14}$/,
                                        message: LanguageUtils.getMessageByKey("Errors.phone-valid", language)
                                    }
                                })}
                            />
                            {errors.phone && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.phone.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="7" className="mb-5">
                            <input
                                // onKeyDown={(e) => handleKeyDown(e, "caregory_id")}
                                id="address"
                                type="text"
                                placeholder={LanguageUtils.getMessageByKey("Contact.address", language)}
                                name="address"
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.address
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                {...register("address", {
                                    maxLength: 2048
                                })}
                            />
                            {errors.address && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.address.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="8" className="">
                            <Controller
                                control={control}
                                name="category_id"
                                rules={{
                                    required: LanguageUtils.getMessageByKey("Errors.category", language),
                                }}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <>
                                        <Select
                                            onKeyDown={(e) => handleKeyDown(e, "content")}
                                            name={name}
                                            inputRef={ref}
                                            className={`w-full border-1 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   
                                                ${errors.category_id
                                                    ? "border-red-600 border-2 focus:border-red-600 ring-red-100 dark:ring-0"
                                                    : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                                }`}

                                            // className={{ control: (state) => state.isFocused ? 'ring-4' : '' }}
                                            onChange={(val) => {
                                                onChange(val);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            // options={[{ value: '', label: 'Select a category' }, ...categories]}
                                            options={options}
                                            placeholder={LanguageUtils.getMessageByKey("Contact.category", language)}
                                            styles={{
                                                placeholder: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    color: "#9ca3af",
                                                }),
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    // borderColor: state.isFocused ? "ring-4" : "green",
                                                }),
                                                multiValueRemove: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    // color: state.isFocused ? "red" : "green"
                                                }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    // backgroundColor: "blue",
                                                    // padding: 5,
                                                    // borderColor: state.isFocused ? "red" : "#d1d5db",
                                                })
                                            }}
                                            // components={{ Control: ControlComponent }}
                                            closeMenuOnSelect={true}
                                            isMulti
                                        >
                                        </Select>
                                        {errors.category_id && (
                                            <div className="mt-1 text-red-600">
                                                <small>{errors.category_id.message}</small>
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <div tabIndex="2" className="mb-5">
                            <input
                                onKeyDown={(e) => handleKeyDown(e, "email")}
                                type="text"
                                placeholder={LanguageUtils.getMessageByKey("Contact.furigana", language)}
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.furigana
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                id="furigana"
                                {...register("furigana", {
                                    required: LanguageUtils.getMessageByKey("Errors.furigana", language),
                                    maxLength: 255
                                })}
                            />
                            {errors.furigana && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.furigana.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="4" className="mb-5">
                            <input
                                onKeyDown={(e) => handleKeyDown(e, "phone")}
                                type="text"
                                placeholder={LanguageUtils.getMessageByKey("Contact.companyName", language)}
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.companyName
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                {...register("companyName", {
                                    maxLength: 255
                                })}
                            />
                            {errors.companyName && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.companyName.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="6" className="mb-5">
                            <input
                                onKeyDown={(e) => handleKeyDown(e, "address")}
                                type="text"
                                placeholder={LanguageUtils.getMessageByKey("Contact.postalCode", language)}
                                autoComplete="false"
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white rounded-md outline-none dark:placeholder:text-gray-200 dark:bg-gray-900   focus:ring-4  
                                    ${errors.postalCode
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                {...register("postalCode", {
                                    maxLength: 9,
                                    pattern: {
                                        value: /^\d{3,9}$/,
                                        message: LanguageUtils.getMessageByKey("Errors.postalCode-valid", language)
                                    }
                                })}
                            />
                            {errors.postalCode && (
                                <div className="mt-1 text-red-600">
                                    <small>{errors.postalCode.message}</small>
                                </div>
                            )}
                        </div>
                        <div tabIndex="9" className="mb-3">
                            <textarea
                                name="content"
                                placeholder={LanguageUtils.getMessageByKey("Contact.content", language)}
                                className={`w-full px-4 py-2 border-2 placeholder:text-gray-800 dark:text-white dark:placeholder:text-gray-200 dark:bg-gray-900   rounded-md outline-none  h-36 focus:ring-4  
                                    ${errors.content
                                        ? "border-red-600 focus:border-red-600 ring-red-100 dark:ring-0"
                                        : "border-gray-300 focus:border-blue-600 ring-gray-100 dark:border-gray-600 dark:focus:border-white dark:ring-0"
                                    }`}
                                {...register("content", {
                                    required: LanguageUtils.getMessageByKey("Errors.content", language)
                                })}
                            />
                            {errors.content && (
                                <div className="mt-1 text-red-600">
                                    {" "}
                                    <small>{errors.content.message}</small>
                                </div>
                            )}
                        </div>
                        {/* <button disabled={!isDirty || !isValid} */}
                        <button tabIndex="10" disabled={isSubmitting}
                            type="submit"
                            className="w-full py-4 border-2 font-semibold hover:text-white bg-gray-100 transition-colors rounded-md hover:bg-blue-600 focus:outline-none focus:ring-offset-2 focus:ring focus:ring-gray-200 px-7 dark:bg-white dark:text-black ">

                            {isSubmitting ? (
                                <svg
                                    className="w-5 h-5 mx-auto text-white dark:text-black animate-spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                LanguageUtils.getMessageByKey("Contact.send-message", language)
                            )}
                        </button>

                        {isSubmitSuccessful && isSuccess && (
                            <div className="mt-3 text-sm text-center text-green-500">
                                {/* {message || "Success. Message sent successfully"} */}
                                {LanguageUtils.getMessageByKey("Contact.send-success", language)}
                            </div>
                        )}
                        {isSubmitSuccessful && !isSuccess && (
                            <div className="mt-3 text-sm text-center text-red-500">
                                {/* {message || "Something went wrong. Please try later."} */}
                                {LanguageUtils.getMessageByKey("Contact.send-fail", language)}
                            </div>
                        )}
                    </div>
                </form>
                <DevTool control={control} />
            </div >
        </Container >
    );
}