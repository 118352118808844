import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, HashRouter, Routes, Route, Switch } from 'react-router-dom';
// import { ConnectedRouter as Router } from 'connected-react-router';
import { publicRoutes } from '../routes';
import CustomScrollbars from '../components/CustomScrollbars';
import { DefaultLayout } from '../components/Layout';
import HeaderFooterLayout from '../components/Layout/HeaderFooterLayout';

class App extends Component {

    render() {
        return (
            <Router>
                <div className='App'>
                    <CustomScrollbars style={{ height: '100vh', width: '100%' }}>
                        <Routes>
                            {publicRoutes.map((route, index) => {
                                const Layout = route.layout ? HeaderFooterLayout : DefaultLayout;
                                const Page = route.component
                                return <Route key={index} path={route.path}
                                    element={<Layout><Page {...route.props} /></Layout>} />
                            })}
                        </Routes>
                    </CustomScrollbars>
                </div>
            </Router>

        )
    }
}

const mapStateToProps = state => {
    return {
        // started: state.app.started,
        // isLoggedIn: state.user.isLoggedIn,
        // language: state.app.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);