import Information from "../components/Information";
import HeaderFooterLayout from "../components/Layout/HeaderFooterLayout";
import PostSection from "../containers/HomePage/Section/PostSection";
import { path, TEXT_WIDGET } from "../utils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../styles/tailwind.min.css';
import '../containers/HomePage/HomePage.scss';
import Contact from "../components/Contact";
import { useSelector } from "react-redux";

const publicRoutes = [
    { path: path.HOME, component: PostSection },
    { path: path.POST, component: PostSection },
    { path: path.ABOUT, component: Information, layout: HeaderFooterLayout, props: { textWidget: TEXT_WIDGET.ABOUT_US_SIDEBAR } },
    { path: path.POLICY, component: Information, layout: HeaderFooterLayout, props: { textWidget: TEXT_WIDGET.PRIVACY_POLICY } },
    { path: path.CONTACT, component: Contact, layout: HeaderFooterLayout }
];

export default publicRoutes;