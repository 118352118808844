import { useState } from "react";
import Topbar from "../../../containers/HomePage/Section/Topbar";
import Topic from "../../../containers/HomePage/Section/Topic";
import { useDispatch, useSelector } from 'react-redux';
import { changeSlugApp } from "../../../store/actions";
import Footer from "../../../containers/HomePage/Section/Footer";


function HeaderFooterLayout({ children }) {

    const dispatch = useDispatch();

    const handleGetPostsByCategory = (slug) => {
        dispatch(changeSlugApp(slug));
    }


    return (
        <div className="home-container bg-white font-family-karla">
            <Topbar />
            <Topic
                handleGetPostsByCategory={handleGetPostsByCategory} />
            <div className="container mx-auto flex flex-wrap py-6">
                <>
                    {children}
                </>
            </div>
            <Footer />
        </div>
    )
}

export default HeaderFooterLayout;

